import {Component, forwardRef, Injector, Input} from '@angular/core'
import {NG_VALUE_ACCESSOR} from '@angular/forms'
import {ControlValueAccessorConnectorDirective} from '../control-value-accessor-connector.directive'

@Component({
  selector: 'cft-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent extends ControlValueAccessorConnectorDirective {
  @Input() value: unknown
  @Input() name?: string
  @Input() hasWarning = false

  constructor(injector: Injector) {
    super(injector)
  }
}
