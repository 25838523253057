import {Component} from '@angular/core'
import {Observable, Subject} from 'rxjs'
import {Dialogistic} from '../dialogistic.interface'

@Component({
  selector: 'cft-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent implements Dialogistic<Props, boolean> {
  data!: Props
  confirm: Subject<boolean> = new Subject<boolean>()

  setData(data: Props): void {
    this.data = data
  }

  reply$(): Observable<boolean> {
    return this.confirm.asObservable()
  }
}

export interface Props {
  message: string
  tip?: string
  /** dialog title */
  title?: string
}
