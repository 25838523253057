import {Component} from '@angular/core'
import {Monologic} from '../monologic.interface'
import {faCheckCircle} from '@fortawesome/pro-light-svg-icons/faCheckCircle'

@Component({
  selector: 'cft-success-dialog',
  templateUrl: './success-dialog.component.html',
})
export class SuccessDialogComponent implements Monologic<Props> {
  data!: Props
  readonly faSuccess = faCheckCircle

  setData(data: Props): void {
    this.data = data
  }
}

interface Props {
  title?: string
  message: string
}
