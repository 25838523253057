import {NgModule} from '@angular/core'
import {InputFormControlDirective} from './form-field/input-form-control.directive'
import {FormFieldComponent} from './form-field/form-field.component'
import {CommonModule} from '@angular/common'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {I18nDirective} from '../i18n/i18n.directive'

@NgModule({
  declarations: [InputFormControlDirective, FormFieldComponent],
  imports: [CommonModule, FontAwesomeModule, I18nDirective],
  exports: [InputFormControlDirective, FormFieldComponent],
})
export class FormsModule {}
