import {NgModule} from '@angular/core'
import {RadioComponent} from './radio.component'
import {ReactiveFormsModule} from '@angular/forms'
import {PipesModule} from '../../../shared/pipes/pipes.module'

@NgModule({
  declarations: [RadioComponent],
  imports: [ReactiveFormsModule, PipesModule],
  exports: [RadioComponent],
})
export class RadioModule {}
