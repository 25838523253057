<cft-dialog *cftI18n="let t" size="lg" [title]="data.heading">
  <div class="sm:flex">
    <fa-icon class="mr-2 hidden text-purple-500 heading-lg sm:mr-4 sm:block" [icon]="infoIcon"></fa-icon>
    <div class="w-full">
      <h3 class="heading-base">{{ data.title }}</h3>
      <p class="mt-2xs text-sm">{{ data.description }}</p>
      @if (data.options?.length) {
        <form [formGroup]="form" class="mt-md flex flex-col space-y-2xs font-normal">
          @for (option of data.options; track option) {
            <cft-radio
              [attr.data-testid]="'radio-' + option.value"
              class="text-base"
              formControlName="selection"
              [value]="option.value"
            >
              {{ option.label }}
            </cft-radio>
          }
          @if (isFormControlInvalid(form, 'selection')) {
            <div class="mt-3 flex text-sm text-[#dc513f]">
              <fa-icon [icon]="errorIcon"></fa-icon>
              <div class="ml-xs">{{ t('feedbackDialog.errors.radioRequired') }}</div>
            </div>
          }
          @if (data.freeTextOnValues?.includes(form.value.selection)) {
            <cft-form-field class="mt-1">
              <textarea formControlName="text"></textarea>
              <span class="error">{{ t('feedbackDialog.errors.freeTextRequired') }}</span>
            </cft-form-field>
          }
        </form>
      }
      @if (data.showSupportContact) {
        <div class="mt-2 flex">
          <fa-icon class="mr-4 text-president-400" [icon]="phoneIcon"></fa-icon>
          <div>
            <h4 class="heading-base">{{ t('feedbackDialog.contact.title') }}</h4>
            <p class="text-base">
              {{ t('feedbackDialog.contact.text') }}
              <span class="font-bold">{{ t('shared.support.phone') }}</span>
            </p>
          </div>
        </div>
      }
    </div>
  </div>
  <div class="mt-2xl flex justify-between text-center">
    <button class="btn btn-sm btn-secondary" (click)="abort()">
      {{ this.data.abortButtonText ?? t('feedbackDialog.abort') }}
    </button>
    <button class="btn btn-sm btn-hero" (click)="confirm()">
      {{ this.data.confirmButtonText ?? t('feedbackDialog.confirm') }}
    </button>
  </div>
</cft-dialog>
