import {NgModule} from '@angular/core'
import {DialogComponent} from './dialog.component'
import {SuccessDialogComponent} from './success-dialog/success-dialog.component'
import {FeedbackDialogComponent} from './feedback-dialog/feedback-dialog.component'
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component'
import {RadioModule} from '../forms/radio/radio.module'
import {ReactiveFormsModule} from '@angular/forms'
import {FormsModule} from '../forms/forms.module'
import {I18nDirective} from '../i18n/i18n.directive'
import {DialogModule as CdkDialogModule} from '@angular/cdk/dialog'
import {FaIconComponent} from '@fortawesome/angular-fontawesome'
import {NgClass} from '@angular/common'

@NgModule({
  declarations: [DialogComponent, SuccessDialogComponent, FeedbackDialogComponent, ConfirmationDialogComponent],
  exports: [DialogComponent, SuccessDialogComponent, FeedbackDialogComponent, ConfirmationDialogComponent],
  imports: [RadioModule, ReactiveFormsModule, FormsModule, I18nDirective, CdkDialogModule, FaIconComponent, NgClass],
})
export class DialogModule {}
