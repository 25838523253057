import {Component, OnDestroy} from '@angular/core'
import {Observable, Subject} from 'rxjs'
import {Dialogistic} from '../dialogistic.interface'
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms'
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import {faPhone} from '@fortawesome/pro-solid-svg-icons/faPhone'
import {takeUntil} from 'rxjs/operators'
import {conditionalValidator, isFormControlInvalid} from '../../forms/form.types'

@Component({
  selector: 'cft-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
})
export class FeedbackDialogComponent<T> implements Dialogistic<Props<T>, FeedbackDialogOutput<T>>, OnDestroy {
  data!: Props<T>
  private output: Subject<FeedbackDialogOutput<T>> = new Subject<FeedbackDialogOutput<T>>()
  form: UntypedFormGroup = new UntypedFormGroup({
    selection: new UntypedFormControl('', Validators.required),
    text: new UntypedFormControl(''),
  })
  readonly infoIcon = faInfoCircle
  readonly phoneIcon = faPhone
  readonly errorIcon = faExclamationTriangle

  private readonly unsubscribe$ = new Subject<void>()

  constructor() {
    this.form.controls.selection.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.form.controls.text.updateValueAndValidity()
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  setData(data: Props<T>): void {
    this.data = data
    if (data.freeTextRequired && data.freeTextOnValues) {
      this.form.controls.text.addValidators(
        conditionalValidator(
          () => !!data.freeTextOnValues?.includes(this.form.controls.selection.value as T),
          [Validators.required],
        ),
      )
    }
  }

  abort(): void {
    this.output.next({confirmed: false})
  }

  confirm(): void {
    this.form.markAllAsTouched()
    const success = this.form.valid || !this.data.options?.length
    let text = undefined

    if (this.data.freeTextOnValues?.length) {
      text = this.form.value.text
    }

    if (success) {
      this.output.next({confirmed: success, value: this.form.value.selection, text})
    }
  }

  reply$(): Observable<FeedbackDialogOutput<T>> {
    return this.output.asObservable()
  }

  protected readonly isFormControlInvalid = isFormControlInvalid
}

interface Props<T> {
  title: string
  heading?: string
  description: string
  options?: {value: T; label: string}[]
  showSupportContact?: boolean
  freeTextOnValues?: T[]
  confirmButtonText?: string
  abortButtonText?: string
  freeTextRequired?: boolean
}

export interface FeedbackDialogOutput<T> {
  confirmed: boolean
  value?: T
  text?: string
}
