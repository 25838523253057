import {AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms'

/**
 * Returns a validator that only is applied if the requiredCondition is true
 * @note if you depend on another control, conditionaValidator might not run when that other control changes
 * In that case, make sure to susbcribe to the other control and call updateValueAndValidity when it's updated
 * Example:
 * someControl = new FormControl(false)
 * conditionalControl = new FormControl('', [conditionalValidator(() => someControl.value, [Validators.required])])
 * // this is needed !
 * someControl.valueChanges.subscribe(() => conditionalControl.updateValueAndValidity())
 */
export function conditionalValidator(
  requiredCondition: () => boolean,
  validators: ValidatorFn[],
): ((control: AbstractControl) => ValidationErrors | null)[] {
  return validators.map(validator => {
    return control => {
      if (requiredCondition()) {
        return validator(control)
      }

      return null
    }
  })
}

export function isFormControlInvalid(
  formGroup: UntypedFormGroup | undefined,
  controlName: string,
): boolean | undefined {
  const control = formGroup?.get(controlName)
  return isControlInvalid(control)
}

export function isControlInvalid(control: AbstractControl | undefined | null): boolean | undefined {
  return control?.touched && control?.invalid
}
