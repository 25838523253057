<cft-dialog *cftI18n="let t" [title]="data.title" data-testid="confirmation-dialog">
  <h3 class="heading-base">{{ data.message }}</h3>
  @if (data.tip) {
    <p class="mt-xs text-sm font-normal">{{ data.tip }}</p>
  }
  <div class="mt-2xl flex justify-between">
    <button class="btn btn-sm btn-secondary" (click)="confirm.next(false)">{{ t('no') }}</button>
    <button class="btn btn-sm btn-hero ml-sm" (click)="confirm.next(true)">{{ t('yes') }}</button>
  </div>
</cft-dialog>
