<label class="inline-flex cursor-pointer items-center">
  <input
    type="radio"
    [formControl]="control | bgoAsFormControl"
    [name]="name || controlName || 'radio'"
    [value]="value"
    [class.input-warning]="hasWarning"
  />
  <span class="ml-md text-base text-president-500">
    <ng-content></ng-content>
  </span>
</label>
